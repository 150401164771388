<template>
  <app-modal-payment-link-detail :show="showModalDetail" @close="showModalDetail = false"
    :payment_link="selectPaymentLink" @update:payment_link="selectPaymentLink = $event" />
  <app-table :apiResponse="apiPaginationResponse.data" :loading="apiPaginationLoading"
    :loadFilterFromUrl="loadFilterFromUrl" emptyIcon="app-icon-empty-payment"
    :emptyTitle="$t('payment.link.empty.title')" :emptyButton="$t('payment.menu.create_link')"
    :emptyDescription="$t('payment.link.empty.description')" :filters="
      this.isMasterBusinessRole
        ? [
          {
            key: 'filter[business_id]',
            type: 'select',
            placeholder: $t('business.table.header_businss_name'),
            items: all_business,
          },
          {
            key: 'filter[title]',
            type: 'text',
            placeholder: $t('payment.link.filter.title'),
            value: '',
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('payment.link.filter.email'),
            value: '',
          },
          {
            key: 'filter[currency]',
            type: 'text',
            placeholder: $t('payment.link.filter.currency'),
            value: '',
          },
          {
            key: 'filter[amount]',
            type: 'number',
            placeholder: $t('payment.link.filter.amount'),
            value: '',
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            placeholder: $t('payment.link.filter.date_created_at'),
            value: '',
          },
        ]
        : [
          {
            key: 'filter[title]',
            type: 'text',
            placeholder: $t('payment.link.filter.title'),
            value: '',
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('payment.link.filter.email'),
            value: '',
          },
          {
            key: 'filter[currency]',
            type: 'text',
            placeholder: $t('payment.link.filter.currency'),
            value: '',
          },
          {
            key: 'filter[amount]',
            type: 'number',
            placeholder: $t('payment.link.filter.amount'),
            value: '',
          },
          {
            key: 'filter[payment_link_id]',
            type: 'text',
            placeholder: $t('payment.link.filter.payment_link_id'),
            value: '',
          },
          {
            key: 'filter[attempt_id]',
            type: 'text',
            placeholder: $t('payment.link.filter.payment_attempt_id'),
            value: '',
          },
          {
            key: 'filter[settlement_id]',
            type: 'text',
            placeholder: $t('payment.link.filter.settlement_id'),
            value: '',
          },
          {
            key: 'filter[has_status]',
            type: 'select',
            placeholder: $t('payment.link.filter.payment_status'),
            value: {},
            items: [
              {
                id: 'paid',
                name: 'Paid',
              },
              {
                id: 'unpaid',
                name: 'Unpaid',
              },
              {
                id: 'paid&processed',
                name: 'Paid & Processed',
              },
            ],
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            placeholder: $t('payment.link.filter.date_created_at'),
            value: '',
          },
        ]
    " @params-changed="paramsChanged" @empty-button-clicked="emptyButtonClicked" @on-item-click="onItemClick">
    <template v-slot:title>
      <div>
        <p class="font-medium">
          {{ $t("payment.menu.link") }}
        </p>
      </div>
    </template>
    <template v-slot:button>
      <app-button width="w-auto" v-if="!dataEmpty" :showf70Icon="false" @click="emptyButtonClicked()">
        <template v-slot:icon>
          <app-icon-outline name="PlusIcon" class="h-6 w-6 text-white mr-2" />
        </template>
        {{ $t("payment.menu.create_link") }}
      </app-button>
    </template>
    <template v-slot:header>
      <th v-if="this.isMasterBusinessRole" class="font-bold">{{ $t("wallet.transaction.business_name") }}</th>
      <th class="font-bold">{{ $t("payment.link.header.amount") }}</th>
      <th class="font-bold">&nbsp;</th>
      <th class="font-bold">{{ $t("payment.link.header.title") }}</th>
      <th class="font-bold">{{ $t("payment.link.header.email") }}</th>
      <th class="font-bold">{{ $t("payment.link.header.date") }}</th>
      <th class="font-bold">{{ $t("payment.link.header.payment_link") }}</th>
    </template>
    <template v-slot:body="slotData">
      <td v-if="this.isMasterBusinessRole">
        <p class="font-medium">
          {{
              slotData.model.business.name
          }}
        </p>
      </td>
      <td>
        <p class="font-medium">
          {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.amount ?? 0.0
              )
          }}
        </p>
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <app-badge :status="slotData.model.paid ? 'success' : 'light'" class="w-16 text-xs">
          {{
              slotData.model.paid
                ? $t("payment.detail.paid")
                : $t("payment.detail.unpaid")
          }}
        </app-badge>
        <app-badge v-if="slotData.model.settlement != null" :status="'success'" class="w-16 text-xs">
          {{ $t("payment.detail.processed") }}
        </app-badge>
      </td>
      <td>
        {{ slotData.model.title }}
      </td>
      <td>
        {{ slotData.model.email }}
      </td>
      <td>
        {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td>
        <div class="flex flex-row space-x-1">
          <!--Start copy link-->
          <app-button-outline class="bg-white" padding="px-2 py-1" @click.stop="copyUrl(slotData.model)">
            <app-icon-outline name="DocumentDuplicateIcon" class="h-5 w-5 text-gray-400" />
            <p class="ml-1 text-xs">{{ $t("payment.detail.copy_link") }}</p>
          </app-button-outline>
          <!--End copy Link-->
          <!--Start resend email-->
          <app-button-outline class="bg-white" padding="px-2 py-1" :disabled="slotData.model.paid"
            @click.stop="resendEmail(slotData.model)">
            <app-icon-outline name="PaperAirplaneIcon" class="h-5 w-5 text-gray-400" />
            <p class="ml-1 text-xs">{{ $t("payment.detail.resend_email") }}</p>
          </app-button-outline>
          <!--End resend Email-->
        </div>
      </td>
    </template>
  </app-table>
</template>
<script>
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  data() {
    return {
      filters: {},
      showModalDetail: false,
      selectPaymentLink: null,
      loadFilterFromUrl: false
    };
  },

  async beforeMount() {
    if (
      !this.$lodash.isEmpty(this.$route.query) &&
      this.$route.query["filter[has_status]"] != null
    ) {
      this.loadFilterFromUrl = true;
      this.filters = {
        "filter[has_status]": this.$route.query["filter[has_status]"],
      };
    }

    await this.fetchPaymentLinks();

    if (this.$route.query.id) {
      this.showModalPaymentLinkById(this.$route.query.id);
    }
  },

  mounted() {
    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
    this.$echo
      .private("business." + this.selectedRole.business_id)
      .listen(".business.payment_create", (data) => {
        this.updatePaymentLink(data.payment_link_id);
        this.$notify(
          {
            group: "success",
            title: data.message,
          },
          5000
        );
      });
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },

    apiPaginationLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },

    errors() {
      return this.$store.getters["paymentStore/errors"];
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business_for_select"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },

    dataEmpty() {
      return (
        this.$lodash.isEmpty(this.apiPaginationResponse?.data?.data) ?? null
      );
    },
  },

  methods: {
    async fetchPaymentLinks() {
      return await this.$store.dispatch(
        "paymentStore/retrievePaymentLinks",
        this.filters
      );
    },

    async updatePaymentLink(payment_link_id) {
      let payment_link = this.apiPaginationResponse.data?.data?.find(
        (payment_link) => payment_link._id == payment_link_id
      );

      if (payment_link == null) return;

      let result = await this.fetchPaymentLinkById(
        payment_link_id,
        payment_link.business?.id
      );

      if (this.$lodash.isEmpty(result.data)) return;

      let payment_link_index =
        this.apiPaginationResponse.data.data.indexOf(payment_link);

      this.apiPaginationResponse.data.data[payment_link_index] = result.data;

      if (this.selectPaymentLink?._id) this.selectPaymentLink = result.data;
    },

    async fetchPaymentLinkById(payment_link_id, business_id = null) {
      return await this.$store.dispatch("paymentStore/getPaymentLink", {
        business_id: business_id,
        payment_link_id: payment_link_id,
      });
    },

    async showModalPaymentLinkById(id) {
      let payment_link = this.apiPaginationResponse.data?.data?.find(
        (payment_link) => payment_link._id == id
      );

      if (this.$lodash.isEmpty(payment_link)) {
        let result = await this.fetchPaymentLinkById(id);
        payment_link = result?.data;
      }

      if (!this.$lodash.isEmpty(payment_link)) {
        this.selectPaymentLink = payment_link;
        this.showModalDetail = true;
      }
    },

    emptyButtonClicked() {
      this.$router.push({ path: "/payment/link/create" });
    },

    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) return;

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },

    paramsChanged(filters, queryParams) {
      this.filters = filters;
      if (this.filters["filter[business_id]"] != null) {
        this.filters["filter[business_id]"] = filters["filter[business_id]"]?.id;
      }
      this.filterFormatDate(filters["filter[created_at_between]"]);
      this.filters["filter[has_status]"] = filters["filter[has_status]"]?.id;
      this.$store.dispatch("paymentStore/retrievePaymentLinks", this.filters);
    },

    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPaymentLink = data;
    },
  },
};
</script>